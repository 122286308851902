// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery-ujs';
import 'bootstrap/dist/js/bootstrap';
import '@client-side-validations/simple-form/src';

import '../channels/magic_code';
import '../channels/pci_payment';
import '../channels/pci_outbound';
import '../channels/salesforce_enquiry';

// Require everything in the scripts directory
const scriptContext = require.context('../scripts', true, /^((?!(test)).)*$/);
scriptContext.keys().forEach(scriptContext);
